@tailwind base;
@tailwind components;
@tailwind utilities;

.playwrite {
  font-family: "Libre Bodoni", serif;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #282828;
}
.slide-1 {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
    url(../src/assets/slide1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.slide-2 {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
    url(../src/assets/slide2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.slide-3 {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
    url(../src/assets/slide3.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.contact-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),url(../src/assets/bg-form.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
.catering-banner{
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)),
  url(../src/assets/Catering-banner.jpg);
  width: 100%;
  height: 90vh;
  background-position: center;
  background-size: cover;
}
